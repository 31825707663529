@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  /* fonts */
  --font-space-grotesk: "Space Grotesk";
  --font-ibm-plex-sans: "IBM Plex Sans";
  --font-roboto: Roboto;
  --font-museomoderno: MuseoModerno;

  /* font sizes */
  --font-size-3xs: 10px;
  --font-size-2xs: 16px;
  --font-size-xs: 20px;
  --font-size-sm: 25px;
  --font-size-base: 30px;
  --font-size-lg: 32px;
  --font-size-xl: 50px;
  --font-size-2xl: 60px;

  /* Colors */
  --color-white: #fff;
  --color-pink-100: #f87faa;
  --color-gray-100: #344a77;
  --color-gray-200: rgba(0, 0, 0, 0.74);
  --color-gray-300: rgba(0, 0, 0, 0.82);
  --color-gray-400: rgba(0, 0, 0, 0.78);
  --color-gray-500: rgba(0, 0, 0, 0.59);
  --color-gray-600: rgba(0, 0, 0, 0.58);
  --color-black: #000;
  --color-gold: #f2cf54;
  --color-teal: #75b5a9;

  /* border radiuses */
  --br-md: 11px;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

body {
  margin: 0 auto;
  overflow-x: hidden !important;
  overflow: overlay;
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  line-height: normal;
  color: #333333;
}

a {
  text-decoration: none;
  transition: 0.2s all;
}

a:hover {
  color: #b1b1b1;
}

button {
  transition: all 0.2s;
}

button:hover {
  background-color: #ffabc8;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: rgb(70, 70, 70) !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: rgb(0, 0, 0) !important;
}

.close {
  opacity: 1;
  font-weight: 400;
  font-size: 38px;
}

.areas_bg_img {
  background: url(../assets//school/registration.png) no-repeat;
  background-position: center bottom;
  background-size: contain;
  height: 600px;
  width: 100% !important;
}

.bg-color {
  background-color: #f4f4f4;
  align-items: center;
  justify-content: center;
}

.centering {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.result_img {
  background-color: transparent;
  padding-top: 100px;
}

.main_logo {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 14px 30px #00000014;
  border-radius: 15px;
  margin: 0px 10px;
}

.main_logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

@media only screen and (min-width: 478px) and (max-width: 991px) {
  .mobile {
    display: none;
  }
}

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  --tw-bg-opacity: 1;
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  padding: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  margin: 1px;
  background-color: #f77eaa;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #f77eaa;
}

::-webkit-scrollbar-thumb:window-inactive {
  opacity: 0.75;
}

.hoverColor {
  color: #464646;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.hoverColor:hover {
  background-color: #f898bb;
  color: #fff;
  border-radius: 10px;
}

.ribbon {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: left;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #8f0808;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

.ribbon span::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #8f0808;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #8f0808;
}

.activeRibbon {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: left;
}

.activeRibbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#51df5a 0%, #113302 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}

.activeRibbon span::before {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #fff;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #fff;
}

.activeRibbon span::after {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #fff;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #fff;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: inherit;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color);
  /* Windows High Contrast Mode */
  background-color: currentColor;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
  background-color: #f77eaa;
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.container-footer {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 2rem;
}

.copyright {
  padding: 2rem 0 0 0;
  font-size: 1rem;
  font-weight: 300;

  text-align: center;
}

.footer-nav {
  padding: 1rem 0;
}

.footer-nav .col {
  max-width: 300px;
}

p.site-decription {
  font-size: 1rem;
  font-weight: 400;
}

.social-medias a {
  width: 3rem;
  height: 3em;

  border-radius: 50%;
}

.social-medias svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
}

.flex-jc-start {
  justify-content: flex-start;
}

.flex-around {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

.flex-g-1 {
  gap: 1rem;
}

.col {
  flex: 1;
}

.col-list ul li {
  list-style: none;
  padding-bottom: 0.2rem;
}

.col-list ul {
  padding: 0;
}

.col-list ul li a {
  text-decoration: none;
  color: #000;
  transition: all 0.5s;
}

.col-list ul li a:hover,
.col-list ul li a:focus {
  color: #666666;
}

.col-title {
  font-family: "Space Grotesk";
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0;
  padding-bottom: 0.5rem;
}

.copyright p {
  font-size: 0.7rem;
}

.footer-item {
  padding-top: 0.5rem;
}

.footer-dark ul li a {
  color: #fff;
}

.footer-dark ul li a:hover,
.col-list ul li a:focus {
  color: #e7e7e7;
}

@media screen and (max-width: 1100px) {
  .footer-nav {
    flex-direction: column;
  }
}

.item-row-suggestion {
  padding: "10px";
  cursor: "pointer";
}

.item-row-suggestion:hover {
  background-color: #e8e8e8 !important;
}

.buttonRectangle {
  margin-top: 2%;
  background-color: #f876a7;
  border: none;
  padding: 0.7rem;
  border-radius: 5px;
  width: 100%;
}

.bg-gray {
  background-color: #212121;
}

.bg {
  background-color: #fff;
}

.text-start {
  margin-bottom: 0.7rem;
}

@media only screen and (max-width: 991px) {
  .txt-sm-center {
    text-align: center !important;
  }
}
