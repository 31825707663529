/* The side navigation menu */
.sidebar {
  margin: 0;
  padding: 2rem;
  width: 400px;
  font-weight: 800;
  position: absolute;
  height: 100%;
  overflow: auto;
  display: inline-block;
}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a:hover:not(.active) {
  color: rgb(134, 134, 134);
}

div.content {
  padding: 2rem 5rem;
  margin-left: 250px;

  height: 1000px;
}

@media screen and (max-width: 700px) {
  .sidebar {
    display: none;
  }

  div.content {
    margin-left: 0;
    padding: 2rem;
  }
}
