.pedia {
  color: var(--color-pink-100);
}

.admissionpedia {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-lg);
  font-family: var(--font-museomoderno);
  text-align: left;
  display: inline-block;
  color: var(--color-black);
}

.Navbar {
  display: flex;
  background-color: #fff;
  height: 6rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.buttonStyle {
  background-color: #f95f97;
  border: none;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 95%;
  margin-top: 30px;
  box-shadow: 0 4px 4px rgba(255, 154, 231, 0.25);
}

.NavbarStyleMarketing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  background-color: #361b25;
}

.LogoStyle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 35px; */
  color: #fff;
  font-size: var(--font-size-lg);
  font-family: var(--font-museomoderno);
}

.navbarItemStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-2xs);
  color: rgb(100, 83, 83);
  font-family: var(--font-museomoderno);
  font-weight: 600;
  text-decoration: none;
}

.SignInButton {
  background-color: #f877a7;
  border: none;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  font-family: var(--font-space-grotesk);
}

.navbarElements ul {
  display: flex;
  margin: 0;
  list-style-type: none;
}

.menuIcon {
  display: none;
}

.navbarElements ul li:not(:last-child) {
  margin-right: 9rem;
}

.navbarElements ul li:last-child {
  margin-left: -3rem;
}

.navbarItems {
  display: flex;
  align-items: baseline;
}

.navbarItemLast {
  display: none;
}

.navbarElementsSchool ul {
  list-style: none;
  text-align: end;
}

.navbarItemSchool {
  margin: 0;
}

.navbarItemSchoolName {
  margin: 0;
  font-size: 1.7rem;
  color: gray;
}

@media only screen and (max-width: 1250px) {
  .Navbar {
    justify-content: space-between;
    padding: 0 3%;
  }

  .navbarElements ul li:not(:last-child) {
    margin-right: 4rem;
  }

  .navbarElements ul li:last-child {
    margin-left: 0;
  }
}

@media only screen and (max-width: 900px) {
  .LogoStyle span {
    font-size: 1.4rem;
  }

  .Navbar {
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    height: 5rem;
  }

  .navigationMenu ul {
    dipslay: none;
  }

  .navbarElementsMenu {
    dipslay: block;
  }

  .navbarElementsMenu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f877a7;
    dipslay: block;
    color: white;
    height: 100vh;
    width: 100%;
    z-index: 1000;
    transition: all 0.5s;
  }

  .navbarElementsMenu ul {
    position: absolute;
    list-style: none;
    top: 45%;
    left: 45%;
    transform: translate(-50%, -50%);
  }

  .navbarElementsMenu ul li a {
    padding-top: 5rem;
    color: white;
    font-size: 1.4em;
    text-transform: uppercase;
    font-family: "Space Grotesk";
  }

  .navbarElements {
    top: 0;
    left: 50%;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .SignInButton {
    display: none;
  }

  .navbarItemLast {
    display: block;
  }

  .menuIcon {
    display: block;
    width: 50px;
    height: 30px;
    position: relative;
    margin: -10px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    align-self: center;
    position: absolute;
    right: 3%;
    z-index: 10000;
  }

  .menuIcon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 60%;
    background-color: #030303;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .menuIcon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .menuIcon span:nth-child(2) {
    top: 9px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .menuIcon span:nth-child(3) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  .open span {
    background-color: #fff;
  }

  .open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 8px;
    left: 8px;
  }

  .open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 29px;
    left: 8px;
  }

  .navbarItemSchoolName {
    font-size: 1rem;
  }

  .navbarItemSchool {
    font-size: 0.7rem;
  }
}
