.AdvancedSearchText {
  text-decoration: underline;
  border-bottom: 0.6px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  float: right;
  margin-top: -15px;
  margin-right: 20px;
}

.filterClass {
  padding: 1rem 0 0;
}

.filterLabel {
  font-family: "Space Grotesk";
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  margin-top: 1.5rem;
}
