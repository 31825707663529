.Description {
  font-size: 2rem;
  color: rgb(44, 44, 44);
  margin-bottom: 1rem;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background: #f67daa;
  background: linear-gradient(to bottom right, #f67daa 0%, #361b25 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 1rem;
  margin-right: 1rem;
}

.MoreDetails {
  font-size: 1.1rem;
  color: rgb(96, 96, 96);
  margin-bottom: 1rem;
  font-weight: 400;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.7;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.button {
  background-color: #f77eaa;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 0.8rem 3rem;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.servicesContainer {
  margin-top: 10%;
  margin-bottom: 5%;
  margin-left: 0;
  margin-right: 0;
}

.aboutUs {
  display: flex;
}

.services {
  font-family: "Space Grotesk";
  font-size: 2.5rem;
  color: rgb(82, 81, 81);
}

@media screen and (max-width: 991px) {
  .Description {
    font-size: 1.5rem;
  }

  .MoreDetails {
    font-size: 1rem;
  }

  .MoreDetails ul li {
    list-style: none;
  }

  .button {
    font-size: 1rem;
    width: 100%;
  }

  .services {
    font-size: 1.5rem;
    padding-top: 30px;
  }

  .aboutUs {
    flex-direction: column-reverse;
  }
}
