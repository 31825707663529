.hero_text {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Space Grotesk", Tahoma, Geneva, Verdana, sans-serif;
  background: #f67daa;
  background-color: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 0 auto;
  padding: 0px 0px 0px 20px;
  line-height: 1.5;
  margin-top: 18%;
}

@media screen and (max-width: 768px) {
  .hero_text {
    font-size: 1.7rem;
    margin-top: 20%;
  }
}

.add_bg_img {
  background: url(../assets/background.svg) no-repeat;
  background-position: start;
  background-size: cover;
  height: 58vh;
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .add_bg_img {
    background-position: top bottom;
  }
}

@media screen and (max-width: 420px) {
  .add_bg_img {
    width: 100%;
    background: url(../assets/background-phone.svg) no-repeat;
    background-position: top bottom;
  }
}

.hero_subText {
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(109, 108, 108);
  text-align: center;
  margin: 0 auto;
  padding: 0;
  line-height: 1.5;
  margin-top: 10px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media screen and (max-width: 768px) {
  .hero_subText {
    font-size: 0.8rem;
    margin-top: 10px;
    padding: 0 3rem;
  }
}
