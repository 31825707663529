.loginContainer {
  background-color: #fef7f9;
  height: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  padding-bottom: 5%;
}

.cardContainer {
  background-color: #fff;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  margin: auto;
  padding: 3rem;
  border: 0.2px solid rgb(198, 198, 198);
  border-radius: 10px;
}

@media only screen and (min-width: 478px) and (max-width: 991px) {
  .cardContainer {
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    margin: 2%;
    box-shadow: 16px 24px 28px -4px rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    border: 0.2px solid rgb(198, 198, 198);
  }
}

.inputField {
  width: 90%;
  background-color: white;
  outline: none;
  border: 2px solid rgb(223, 223, 223);
  padding: 0.8rem;
  border-radius: 30px;
  color: rgb(32, 31, 31);
  margin: 0.5rem;
}

.loginContainerMargin {
  position: relative;
  margin: auto;
  margin-top: 787px;
  background-color: blue;
}

.buttonStyle {
  width: 90%;
  margin-top: 5%;
  background-color: #f876a7;
  border: none;
  padding: 0.7rem;
  border-radius: 30px;
  box-shadow: 16px 24px 28px -4px rgba(0, 0, 0, 0.1);
}

.facebookLogin {
  background-color: #3c5997;
  border-radius: 30px;
  box-shadow: 16px 24px 28px -4px rgba(0, 0, 0, 0.1);
  color: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.googleLogin {
  background-color: aliceblue;
  border-radius: 30px;
  box-shadow: 16px 24px 28px -4px rgba(0, 0, 0, 0.1);
  color: rgb(78, 77, 77);
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 0.4px solid rgb(167, 167, 167);
  cursor: pointer;
}

.or {
  color: gray;
}

.labelStyle {
  color: rgb(69, 69, 69);
  margin-left: 2%;
}

.errorStyle {
  width: 90%;
  background-color: aliceblue;
  outline: none;
  box-shadow: 16px 24px 28px -4px rgba(255, 57, 57, 0.1);
  border: none;
  padding: 0.8rem;
  border-radius: 30px;
  color: rgb(32, 31, 31);
  margin: 0.5rem;
  border: 1px solid;
  border-color: red;
  animation: skew-x-shake 0.2s ease-in-out 0s 4;
}

.errorStyle::placeholder {
  color: rgb(252, 113, 113);
}

/* A CSS animation that is used to shake the input field when the user enters an invalid input. */
@keyframes skew-x-shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.loginAs {
  width: 100%;
  margin-right: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 30px;
  padding: 2rem;
  display: flex;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.loginAs:hover {
  width: 100%;
  margin-right: 10px;
  border: 2px solid #f1a0bf;
  border-radius: 30px;
  padding: 2rem;
  display: flex;
  margin-bottom: 10px;
  background-color: #fef7f9;
}

.loginAsText {
  color: #f876a7;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.loginAsText:hover {
  color: #f876a7;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: #ac3261;
}

.loginAsContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
