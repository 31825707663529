.carousel_container {
  text-align: center;
  width: 100%;
  align-items: center;
  justify-items: center;
  background: #ffb0cd;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.carousel_containerDark {
  text-align: center;
  width: 100%;
  align-items: center;
  justify-items: center;
  background: #212121;
}

.headerText {
  padding-bottom: 1.2%;
  color: #03536f;
  font-size: 1.7rem;
}

.imageContainerStyle {
  align-items: center;
  justify-items: center;
  margin: auto;
  margin-bottom: 10%;
  width: 100%;
  height: 100%;
}

.imageStyle {
  border-radius: 100%;
}
