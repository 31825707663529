.icon {
  position: absolute;
  top: 24px;
  left: 0;
  width: 1452px;
  height: 986px;
  object-fit: cover;
}

.Navbar {
  display: flex;
  position: fixed;
  height: 6rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.5s;
}

.addSchoolChild {
  position: absolute;
  top: 341px;
  left: 988px;
  background-color: var(--color-white);
  width: 143px;
  height: 233px;
}

.icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1444px;
  height: 1659px;
  overflow: hidden;
}

.addSchoolItem {
  position: absolute;
  top: 30.27%;
  left: 88.54%;
  width: 0;
  height: 0;
}

.addSchoolInner {
  height: 3.73%;
  width: 51.77%;
  top: 29.92%;
  right: -8.44%;
  bottom: 66.34%;
  left: 56.67%;
}

.addSchoolChild1,
.addSchoolInner,
.groupIcon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.groupIcon {
  height: 1.81%;
  width: 14.77%;
  top: 12.75%;
  right: 1.34%;
  bottom: 85.43%;
  left: 83.89%;
}

.addSchoolChild1 {
  top: 16.17%;
  bottom: 61.74%;
}

.addSchoolChild1,
.addSchoolChild2 {
  height: 22.09%;
  width: 88.34%;
  right: 11.66%;
  left: 0;
}

.addSchoolChild2,
.vectorIcon,
.zacharyKeimigNxjgmzflcjiUnsIcon {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.addSchoolChild2 {
  top: 39.12%;
  bottom: 38.79%;
}

.vectorIcon {
  height: 43.63%;
  width: 29.08%;
  top: -37.99%;
  right: 185.76%;
  bottom: 94.36%;
  left: -114.84%;
}

.zacharyKeimigNxjgmzflcjiUnsIcon {
  height: 17.01%;
  width: 36.92%;
  top: -88.85%;
  right: 193.2%;
  bottom: 171.85%;
  left: -130.11%;
  object-fit: cover;
}

.vectorParent {
  position: absolute;
  height: 55.17%;
  width: 106.47%;
  top: 51.8%;
  right: -197.77%;
  bottom: -6.97%;
  left: 191.31%;
  transform: rotate(159.02deg);
  transform-origin: 0 0;
}

.groupChild {
  cursor: pointer;
  border: 1px solid var(--color-pink-100);
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .addSchoolChild {
    top: 341px;
    left: 988px;
    background-color: var(--color-white);
    width: 143px;
    height: 233px;
  }

  .addSchoolItem {
    position: absolute;
    top: 30.27%;
    left: 88.54%;
    width: 0;
    height: 0;
  }

  .addSchoolInner {
    height: 3.73%;
    width: 51.77%;
    top: 29.92%;
    right: -8.44%;
    bottom: 66.34%;
    left: 56.67%;
  }

  .addSchoolChild1,
  .addSchoolInner,
  .groupIcon {
    position: absolute;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .groupIcon {
    height: 1.81%;
    width: 14.77%;
    top: 12.75%;
    right: 1.34%;
    bottom: 85.43%;
    left: 83.89%;
  }

  .addSchoolChild1 {
    top: 16.17%;
    bottom: 61.74%;
  }

  .addSchoolChild1,
  .addSchoolChild2 {
    height: 22.09%;
    width: 88.34%;
    right: 11.66%;
    left: 0;
  }

  .addSchoolChild2,
  .vectorIcon,
  .zacharyKeimigNxjgmzflcjiUnsIcon {
    position: absolute;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .addSchoolChild2 {
    top: 39.12%;
    bottom: 38.79%;
  }

  .vectorIcon {
    height: 43.63%;
    width: 29.08%;
    top: -37.99%;
    right: 185.76%;
    bottom: 94.36%;
    left: -114.84%;
  }

  .zacharyKeimigNxjgmzflcjiUnsIcon {
    height: 17.01%;
    width: 36.92%;
    top: -88;
    right: 193.2%;
    bottom: 171.85%;
    left: -130.11%;
    object-fit: cover;
  }

  .vectorParent {
    position: absolute;
    height: 55.17%;
    width: 106.47%;
    top: 51.8%;
    right: -197.77%;
    bottom: -6.97%;
    left: 191.31%;
    transform: rotate(159.02deg);
    transform-origin: 0 0;
  }

  .groupChild {
    cursor: pointer;
    border: 1px solid var(--color-pink-100);
    padding: 0;
    background-color: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .addSchoolChild {
    top: 341px;
    left: 988px;
    background-color: var(--color-white);
    width: 143px;
    height: 233px;
  }
}

.rectangleParent {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 23.79%;
  width: 52.28%;
  top: 76.21%;
  right: 4.56%;
  bottom: 0;
  left: 43.16%;
}

.increaseTheDiscoverabilityO {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  display: inline-block;
  margin-top: 1rem;
}

.increaseTheDiscoverabilityOWrapper {
  position: absolute;
  height: 11.58%;
  width: 94.3%;
  top: 51.45%;
  right: 4.56%;
  bottom: 36.98%;
  left: 1.14%;
}

.addYourSchoolToThePlatfor {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 3.8rem;
  font-family: var(--font-museomodernoq);
  display: flex;
  align-items: center;
  background: #f67daa;
  background: linear-gradient(to bottom right, #f67daa 0%, #361b25 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.getStarted {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: var(--font-size-2xl);
  display: flex;
  align-items: center;
  /* color: #4d4d4d; */

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 768px) {
  .addYourSchoolToThePlatfor {
    font-size: 2rem;
  }
}

.getStarted {
  height: 30.91%;
  width: 72.44%;
  top: 30.91%;
  left: 14.17%;
  font-size: var(--font-size-sm);
  font-family: var(--font-space-grotesk);
  color: var(--color-white);
  text-align: center;
  justify-content: center;
  color: white;
}

.rectangleGroup {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 23.79%;
  width: 40.55%;
  top: 87.21%;
  right: 59.12%;
  bottom: 0;
  left: 0.33%;
}

@media screen and (max-width: 768px) {
  .rectangleGroup {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    height: 23.79%;
    width: 40.55%;
    top: 36.21%;
    right: 59.12%;
    bottom: 0;
    left: 0.33%;
  }
}

.groupParent {
  position: absolute;
  height: 4.09%;
  width: 42.64%;
  top: 5.26%;
  right: 48.75%;
  bottom: 90.64%;
  left: 8.61%;
  font-size: 28px;
  color: var(--color-black);
}

@media screen and (max-width: 768px) {
  .groupParent {
    width: 90%;
  }
}

.groupItem,
.signIn {
  position: absolute;
  left: 0;
}

.signIn {
  top: 10px;
  display: inline-block;
}

.groupItem {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-pink-100);
  top: 0;
  border-radius: 17px;
  width: 119px;
  height: 43.1px;
}

.rectangleContainer {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 101px;
  width: 119px;
  height: 43.1px;
}

.pedia {
  color: var(--color-pink-100);
}

.admissionpedia {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-lg);
  font-family: var(--font-museomoderno);
  text-align: left;
  display: inline-block;
  color: var(--color-black);
}

.schools {
  left: 0;
}

.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dc7ea7;
  width: 1469px;
  height: 519px;
}

.partnerSchools {
  position: absolute;
  top: 51.07px;
  left: 571px;
  font-weight: 500;
  display: inline-block;
  width: 362.7px;
  height: 56.86px;
}

.groupDiv {
  position: absolute;
  top: 1774px;
  left: -4px;
  width: 1469px;
  height: 519px;
  display: none;
  text-align: center;
  font-family: var(--font-roboto);
}

.logoschool2V21Icon {
  top: 0;
  left: 0;
  width: 260.68px;
  height: 258.02px;
}

.logoschool1V11Icon,
.logoschool2V21Icon,
.logoschool4V21Icon {
  position: absolute;
  object-fit: cover;
}

.logoschool4V21Icon {
  top: 27.11px;
  left: 307.9px;
  width: 206.84px;
  height: 204.73px;
}

.logoschool1V11Icon {
  top: 6px;
  left: 575.09px;
  width: 175.68px;
  height: 252.41px;
}

.logoschool5V11Icon,
.logoschool61Icon {
  position: absolute;
  top: 27px;
  left: 817.09px;
  width: 207px;
  height: 205px;
  object-fit: cover;
}

.logoschool61Icon {
  top: 12.15px;
  left: 1047.44px;
  width: 242.73px;
  height: 240.26px;
}

.logoschool2V21Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 1290.17px;
  height: 258.41px;
}

.logoschool62Icon {
  position: absolute;
  left: 0;
  width: 232px;
  object-fit: cover;
}

.logoschool62Icon,
.logoschool71Icon {
  top: 0;
  height: 232px;
}

.logoschool71Icon,
.logoschool81Icon {
  position: absolute;
  object-fit: cover;
}

.logoschool71Icon {
  left: 288px;
  width: 226px;
}

.logoschool81Icon {
  top: 10px;
  left: 570px;
  width: 187px;
  height: 212px;
}

.logoschool101Icon,
.logoschool91Icon {
  position: absolute;
  object-fit: cover;
}

.logoschool91Icon {
  top: 13px;
  left: 819px;
  width: 185px;
  height: 206px;
}

.logoschool101Icon {
  top: 27px;
  left: 1049px;
  width: 234px;
  height: 205px;
}

.component1 {
  position: absolute;
}

.logoschool62Parent {
  position: absolute;
  top: 17px;
  left: 1323px;
  width: 1283px;
  height: 232px;
}

.component1 {
  top: 1921px;
  left: -331px;
  width: 2606px;
  height: 258.41px;
  display: none;
}

.addSchoolChild3,
.logoschool22 {
  position: absolute;
}

.logoschool22 {
  height: 3.87%;
  width: 20.42%;
  top: 22.52%;
  right: 78.68%;
  bottom: 73.61%;
  left: 0.9%;
}

.addSchoolChild3 {
  height: 22.09%;
  width: 88.34%;
  top: 54.18%;
  right: -61.74%;
  bottom: 23.73%;
  left: 73.4%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.vector {
  border: 2px solid var(--color-gray-100);
  background-color: transparent;
  position: absolute;
  height: 0.84%;
  width: 8.78%;
  top: 37.22%;
  right: 93.3%;
  bottom: 61.94%;
  left: -2.08%;
  box-sizing: border-box;
}

.addSchoolChild4 {
  position: absolute;
  height: 22.09%;
  width: 88.34%;
  top: 67.88%;
  right: 11.66%;
  bottom: 10.03%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.addSchoolChild5 {
  left: 0;
}

.addSchoolChild5,
.addSchoolChild6,
.groupIcon1,
.groupIcon2 {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.addSchoolChild5 {
  height: 3.2%;
  width: 16.93%;
  top: 40.95%;
  right: 83.07%;
  bottom: 55.85%;
}

.addSchoolChild6 {
  height: 3.2%;
  width: 17.41%;
  top: 65.61%;
  right: 5.78%;
  bottom: 31.19%;
  left: 76.81%;
}

.groupIcon1 {
  height: 1.86%;
  width: 3.23%;
  top: 12.88%;
  right: 36.63%;
  bottom: 85.25%;
  left: 60.14%;
}

.groupIcon2 {
  height: 3.73%;
  width: 51.77%;
  top: 42.77%;
  right: 48.23%;
  bottom: 53.5%;
  left: 0;
}

.rectangleParent1 {
  position: absolute;
  top: 0;
  left: 427px;
  width: 1469px;
  height: 519px;
}

.logoschool4V21Icon1 {
  position: absolute;
  top: 27.11px;
  left: 436px;
  width: 206.84px;
  height: 204.73px;
  object-fit: cover;
}

.logoschool81Icon1 {
  top: 27px;
  left: 1893px;
  width: 187px;
  height: 212px;
}

.logoschool101Icon1,
.logoschool81Icon1,
.logoschool91Icon1 {
  position: absolute;
  object-fit: cover;
}

.logoschool91Icon1 {
  top: 30px;
  left: 2142px;
  width: 185px;
  height: 206px;
}

.logoschool101Icon1 {
  top: 44px;
  left: 2372px;
  width: 234px;
  height: 205px;
}

.component2,
.partnerSchools1 {
  position: absolute;
  top: 143px;
  left: 0;
  width: 2606px;
  height: 258.41px;
}

.partnerSchools1 {
  top: 1249px;
  left: -436px;
  height: 519px;
  text-align: center;
  font-family: var(--font-roboto);
}

.features {
  position: absolute;
  height: 48.93%;
  width: 94.1%;
  top: 26.73%;
  right: 1.11%;
  bottom: 24.35%;
  left: 4.79%;
}

.schoolAdmissionMadeEasier {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 461px;
  height: 247px;
}

.haveQuestionsAbout {
  margin-block-start: 0;
  margin-block-end: 0;
}

.fillOutThe {
  margin: 0;
}

.haveQuestionsAboutPricing {
  position: absolute;
  top: 300px;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 500;
  display: inline-block;
  width: 442px;
}

.outlinebrandsinstagramIcon {
  left: 0;
}

.outlinebrandsgoogleIcon,
.outlinebrandsinstagramIcon,
.outlinebrandstwitterIcon {
  margin-top: 40px;
  width: 40px;
  height: 40px;
}

.outlinebrandstwitterIcon {
  left: 50px;
}

.outlinebrandsgoogleIcon {
  left: 100px;
}

.rectangleDiv {
  position: absolute;
}

.schoolAdmissionMadeEasierParent {
  position: absolute;
  top: 169px;
  left: 120px;
  width: 461px;
  height: 643px;
}

.rectangleDiv {
  top: -102px;
  left: -106px;
  background-color: #4d4d4d;
  width: 799px;
  height: 1008px;
  display: none;
}

.frameDiv {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 26px;
  background-color: var(--color-white);
  box-shadow: 0 42px 34px rgba(255, 255, 255, 0.43);
  width: 636px;
  height: 788px;
}

.textInput,
.textInput1 {
  border: 0;
  font-family: var(--font-space-grotesk);
  font-size: var(--font-size-2xs);
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 253.05px;
  width: 281.66px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.textInput1 {
  top: 103.42px;
  left: 2.2px;
  width: 532.51px;
}

.label {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 150%;
  display: inline-block;
}

.textInputParent {
  top: 0;
  left: 0;
  width: 534.71px;
  height: 389px;
}

.groupInput {
  border: 0;
  display: block;
  font-family: var(--font-space-grotesk);
  font-size: var(--font-size-xs);
  background-color: transparent;
  height: 12.09%;
  width: 99.59%;
  top: 87.91%;
  right: 0;
  bottom: 0;
  left: 0.41%;
}

.groupContainer {
  top: 155px;
  left: 51px;
  width: 534.71px;
  height: 555.11px;
}

.contactUs,
.frameParent,
.groupContainer {
  position: absolute;
}

.frameParent {
  top: 0;
  left: 0;
  width: 636px;
  height: 788px;
}

.contactUs {
  top: 67px;
  left: 51px;
  font-size: var(--font-size-lg);
  letter-spacing: 0.01em;
  line-height: 150%;
  display: flex;
  color: var(--color-pink-100);
  align-items: center;
  width: 230px;
  height: 36px;
}

.rectangleParent2 {
  position: absolute;
  top: 102px;
  left: 747px;
  width: 636px;
  height: 788px;
  font-size: var(--font-size-2xs);
  color: #6f7482;
}

.contactForm {
  position: absolute;
  top: 6056px;
  left: 0;
  width: 1440px;
  height: 1008px;
  font-size: var(--font-size-2xl);
}

.addSchool {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 7599px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-space-grotesk);
}

.InputStyleContact {
  background-color: transparent;
  border: #eae9e9 2px solid;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  color: #f95f97;
  font-family: var(--font-space-grotesk);
  font-size: var(--font-size-2xs);
  width: 90%;
  margin-top: 10px;
  border-radius: 10px;
}

.InputStyleContact:focus {
  border: #f77eaa 2px solid;
  outline: none;
}

.labelStyle {
  font-family: var(--font-space-grotesk);
  font-size: var(--font-size-2xs);
  margin-top: 30px;
}

.buttonStyle {
  background-color: #f95f97;
  border: none;
  color: #fff;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 95%;
  margin-top: 30px;
  box-shadow: 0 4px 4px rgba(255, 154, 231, 0.25);
}

.NavbarStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  /* background-color: #361b25; */
}

.NavbarStyleMarketing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  background-color: #361b25;
}

.LogoStyle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 35px; */
  color: #fff;
  font-size: var(--font-size-lg);
  font-family: var(--font-museomoderno);
}

.navbarItemStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-2xs);
  color: rgb(100, 83, 83);
  font-family: var(--font-museomoderno);
  font-weight: 600;
  text-decoration: none;
  margin-left: 30px;
}

.SignInButton {
  background-color: #f877a7;
  border: none;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  z-index: -100;
  font-family: var(--font-space-grotesk);
}

@media screen and (max-width: 768px) {
  .Navbar {
    height: 4rem;
  }

  .SignInButton {
    font-size: 0.8rem;
    padding: 0.5rem 1.2rem;
  }

  .LogoStyle {
    font-size: var(--font-size-xs);
  }
}
