.sidebar {
  display: fixed;
  width: 250px;
  height: 100%;
}

.sidebarItem {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  color: rgb(107, 106, 106);
  text-decoration: none;
}

.sidebarItem:hover {
  background-color: #f77eaa;
  color: white;
  border-radius: 20px;
  text-decoration: none;
}

.sidebarItemIcon {
  margin-right: 10px;
}

.sidebarItem:hover {
  color: white;
}

.sidebarItemActive {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  color: rgb(107, 106, 106);
  text-decoration: none;
  background-color: #f77eaa;
  color: white;
  border-radius: 20px;
}

.sidebarItemActive:hover {
  color: white;
}
