.mainContainer {
  background-color: #fef7f9;
  padding-bottom: 6rem;
  min-height: 100vh;
}

.AutoComplete {
  position: relative;
  z-index: 2;
}

.Location {
  font-size: 0.9em;
  color: rgb(110, 109, 109);
  margin-left: -18%;
}

@media (max-width: 768px) {
  .Location {
    margin-left: -10%;
  }
}

.iconColor {
  color: gray;
}

.schoolName {
  font-size: 1.2em;
  font-weight: 600;
  padding-bottom: 10px;
}

.Tags {
  font-size: 0.9em;
  background-color: rgb(100, 174, 111);
  border-radius: 20px;
  padding-left: 6px;
  color: azure;
  margin-top: 5px;
  padding-right: 6px;
}

.LocationTags {
  font-size: 0.9em;
  background-color: #f77eaa;
  border-radius: 20px;
  padding-left: 6px;
  color: azure;
  margin-top: 5px;
  margin-left: 5px;
  padding-right: 6px;
}

.TextStyle {
  font-size: 0.9em;
  color: rgb(110, 109, 109);
}

.ApplyButton {
  font-size: 0.8rem;
  background-color: #f77eaa;
  padding-left: 10px;
  padding-right: 10px;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
  color: white;
  padding-bottom: 5px;
  border-radius: 5px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #00000029;
  height: 35px;
  width: 7rem;
  text-align: center;
  transition: all 0.5s ease-out;
}
.ApplyButtonPhone {
  font-size: 0.8rem;
  background-color: #f794b8;
  padding-left: 10px;
  padding-right: 10px;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  padding-top: 10px;
  color: white;
  padding-bottom: 10px;
  border-radius: 5px;
  border-radius: 10px;
  border: 2px solid #f77eaa;
  margin-top: 6%;
  cursor: pointer;
  box-shadow: 0px 0px 10px #00000029;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: all 0.5s ease-out;
  margin-bottom: 10px;
}

.ApplyButtonPhone:hover {
  background-color: #f77eaa;
}

.classes {
  font-size: 0.8rem;
  border: 2px solid #f77eaa;
  padding-left: 10px;
  padding-right: 10px;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
  color: #f77eaa;
  padding-bottom: 5px;
  border-radius: 5px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #00000029;
  height: 35px;
  width: 10rem;
  text-align: center;
  transition: all 0.5s ease-out;
}

.ApplyButton:hover {
  border: 2px solid;
  border-color: #f77eaa;
  background-color: aliceblue;
  color: rgb(39, 37, 37);
}

.TextAfterIcon {
  font-size: 0.8em;
  color: rgb(110, 109, 109);
  margin-left: 5px;
}

.AllClass {
  font-size: 0.9em;
  font-weight: 500;
  margin-left: 5px;
}

.IconStyles {
  color: #f77eaa;
  font-size: 1.8em;
}

.hover-shadow:hover {
  -webkit-box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6);
  box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6);
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}

.schoolImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.SortBy {
  width: 15%;
  float: right;
}

@media (max-width: 768px) {
  .SortBy {
    display: none;
  }
}

.Filter {
  width: 15%;
  float: right;
  font-size: 1.1em;
  color: gray;
  display: flex;
}

@media (min-width: 768px) {
  .Filter {
    display: none;
  }
}

.card {
  box-shadow: -1px 0px 20px -4px rgba(153, 153, 153, 0.25);
}

.gridWrapper {
  padding: 4rem;
}

@media screen and (max-width: 1253px) {
  .gridWrapper {
    padding: 2rem;
  }
}
