.contactFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 55%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.contactForm {
  padding: 30px 40px;
  background-color: #ffffff;
  border-radius: 12px;
  width: 550px;
  margin-bottom: 2rem;

  /* max-width: 400px; */
}

.contactForm textarea {
  resize: none;
}

.contactForm .formInput,
.formTextArea {
  background-color: #f0f4f5;
  height: 50px;
  padding-left: 16px;
}

.contactFormMarketing input {
  background-color: #424242;
  height: 50px;
  padding-left: 16px;
  color: white;
  border: none;
}

.contactFormMarketing input:focus {
  background-color: #424242;
  outline: 1px solid #864d61;
  color: white;
}

.contactFormMarketing textarea:focus {
  background-color: #424242;
  outline: 1px solid #864d61;
  color: white;
}

.contactFormMarketing textarea {
  background-color: #424242;
  padding-left: 16px;
  color: white;
  border: none;
}

.contactFormMarketing .formControl:focus {
  color: white;
  background-color: #424242;
  border-color: #864d61;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #864d61;
}

.contactFormMarketing .formError:focus {
  border-color: #f87faa;
  box-shadow: inset 0 1px 1px #424242, 0 0 8px #f87faa;
  transition: all 0.2s;
}

.contactFormMarketing textarea:focus {
  background-color: #424242;
  padding-left: 16px;
  color: white;
  border: none;
}

.contactForm .formTextArea {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contactForm .formControl::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contactFormMarketing .formControl::placeholder {
  color: #919191;
  font-weight: 500;
  opacity: 1;
}

.contactForm .formControl:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.formError::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: bold;
}

.contactForm .formControl::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contactForm .formControl:focus {
  border-color: #f87faa;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f87faa;
}

.contactForm .title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.contactForm .description {
  color: #aeb4b9;
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}

.contactForm .submitButtonWrapper {
  text-align: center;
}

.contactForm .submitButtonWrapper input {
  border: none;
  border-radius: 4px;
  background-color: #f87faa;
  color: white;
  text-transform: uppercase;
  padding: 15px 60px;
  font-weight: 500;
  letter-spacing: 2px;
  width: 100%;
  transition: all 0.5s;
}

.contactForm .submitButtonWrapper input:hover {
  background-color: #ffc3d9;
}

.wrapper {
  background-color: #f87faa;
  height: 110vh;
}

.additionalInfo {
  margin: 0;
  padding: 0;
  padding-bottom: 1rem;
  text-align: center;
  max-width: 500px;
  color: #fff;
  font-size: 1rem;
}

.formError {
  border-color: #ff2222;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #ff2222;
  color: #ff2222;
  transition: all 0.5s;
}

.formError:focus {
  border-color: #f87faa;
  box-shadow: inset 0 1px 1px rgb(255, 255, 255), 0 0 8px #f87faa;
  transition: all 0.2s;
}

.errorMessage {
  padding: 0;
  color: #ff2222;
  transition: all 0.2s;
}

.requestButton {
  border-radius: 5px;
}

.buttonRectangle {
  margin-top: 2%;
  background-color: #f876a7;
  border: none;
  padding: 0.7rem;
  border-radius: 5px;
  width: 100%;
}

.contactFormMarketing {
  background-color: #1e1e1e;
  color: white;
  text-align: left;
  padding: 4rem 2.5rem;
  border-radius: 15px;
  max-width: 650px;
}

.contactFormMarketing .title {
  font-size: 2rem;
  padding-bottom: 1rem;
}

.buttonRectangleMarketing {
  margin-top: 5%;
  background-color: #864d61;
  border: none;
  padding: 1rem;
  border-radius: 5px;
  font-weight: bold;
  width: 100%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 16px;
  font-size: 18px;
  text-align: center;
  z-index: 9999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

@media screen and (max-width: 768px) {
  .contactForm {
    max-width: 400px;
  }

  .contactFormMarketing {
    width: auto;
  }

  .contactFormMarketing .title {
    font-size: 1.5rem;
  }
}
