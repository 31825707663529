.hero_text {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: "Space Grotesk", sans-serif;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-left: 30%;
  margin-top: 20%;
  padding-right: 30%;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .hero_text {
    font-size: 1.2rem;
    margin-top: 30%;
    padding-left: 10%;
    padding-right: 10%;
  }
}

.add_bg_img {
  background: url(../assets/ForSchools/bg.png) no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .add_bg_img {
    height: 60vh;
    background-position: center bottom;
  }
}

.hero_subText {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  margin: 0 auto;
  line-height: 1.5;
  margin-top: 10px;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  padding-left: 30%;
  padding-right: 30%;
}

@media screen and (max-width: 768px) {
  .hero_subText {
    font-size: 0.8rem;
    margin-top: 10px;
    padding-left: 10%;
    padding-right: 10%;
  }
}

.getStarted {
  background: #f67daa;
  color: #fff;
  border: none;
  padding: 10px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-family: "Space Grotesk", sans-serif;
}

.getStarted:hover {
  background-color: #ffabc8;
}

.signinButton {
  color: #d4d0d2;
  border: none;
  padding: 10px 40px;
  border-radius: 25px;
  border: 1px solid #d4d0d2;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  font-family: "Space Grotesk", sans-serif;
  margin-right: 10px;
}

.signinButton:hover {
  background-color: #fff;
  color: black;
}

@media screen and (max-width: 768px) {
  .getStarted {
    font-size: 1rem;
    padding: 0.7rem 1rem;
  }

  .signinButton {
    font-size: 1rem;
    padding: 0.7rem 1.2rem;
  }
}
