.mainContainer {
  width: 60%;

  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    width: 95%;
  }
}

.field {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
}

.add_bg_img {
  background: url(../assets/ForSchools/bg.png) no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  width: 100% !important;
  padding-bottom: 10%;
}

.searchInput {
  width: 500px;
  padding: 15px;
  background-color: #1c1c1c;
  outline: none;
}
