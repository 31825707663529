.aboutContainer {
    padding: 0;
}

.heroContainer {
    background: url(../assets/ForSchools/bg.png) no-repeat;
    background-position: center center;
    padding: 5rem 0;
}

.heroTitle {
    font-size: 2.5rem;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: aliceblue;
    text-align: center;
    margin: 0 auto;
    padding: 0px 0px 0px 20px;
    line-height: 1.5;
    margin-top: 20%;
}

.heroBtn {
    margin-top: 2rem;
}