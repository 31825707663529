.linksTitle {
  color: #94a2ab !important;
  transition: all linear.3s;
  line-height: 30px;
  text-transform: uppercase;
  font-size: 12px;
}

.linksTitle:hover {
  color: #fb9819 !important;
}

.socialIcon {
  display: inline-block;
  color: #f77eaa;
  border: 1px solid #d21359;
  border-radius: 30px;
  margin-right: 14px;
}

.socialIcon svg {
  color: white;
}

.socialIcon i {
  color: white;
}

.footerGrid {
  background-color: whitesmoke;
  color: #fd5b96;
  width: 100%;
  margin: 0;
  padding: 0 10%;
  font-family: "Poppins", sans-serif;
  padding-top: 15px;
  display: flex;
  z-index: 100000;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .footerGrid {
    display: block;
  }
}

.copyright {
  color: #94a2ab;
  text-align: center;
  font-size: 14px;
}

.footerLinks {
  text-decoration: none;
  color: #94a2ab;
  font-size: 0.8rem;
}

.footerDark {
  background-color: black;
  color: #fff;
}
