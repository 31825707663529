.carousel_container {
  text-align: center;
  align-items: center;
  justify-items: center;
  position: relative;
}

.headerText {
  padding-bottom: 1.2%;
  color: #03536f;
  font-size: 1.7rem;
}

.imageContainerStyle {
  align-items: center;
  justify-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.testimonials {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  padding-top: 5%;
  margin-bottom: 0;
}

.imageStyle {
  border-radius: 100%;
}

.reviewStyle {
  font-family: "Space Grotesk";
  color: #444444;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 10% 15%;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  max-width: 450px;
  margin: auto;
  opacity: 0.9;
  z-index: -10;
}

@media screen and (max-width: 768px) {
  .reviewStyle {
    padding: 10% 5%;
  }

  .testimonials {
    flex-direction: column;
    margin: 0;
  }
}

.reviewTextStyle {
  font-weight: 500;
  padding-bottom: 15%;
}

.schoolNameStyle {
  font-weight: bold !important;
}

.titleNameStyle {
  font-weight: 300;
  font-size: 0.8rem;
  margin-bottom: 2rem !important;
}
