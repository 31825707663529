.navItem {
  margin-left: 20px;
  line-height: 45px;
  text-decoration: none;
  color: rgb(36, 35, 35);
}

.navItem i {
  padding: 0 6px;
  font-size: 12px;
}

.navItem svg {
  height: 16px;
}

.navItemIconType {
  margin-left: 20px;
  color: black;
  padding-left: 8px;
  text-decoration: none;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-items: center;
}

.navItemIconType svg {
  height: 21px;
}

.signInButton {
  background: #fb9819;
  border: 0px solid;
  margin-left: 16px;
  padding: 6px 10px;
  color: white;
  border-radius: 30px;
}

.avatar {
  border: 0px solid;
  margin-left: 16px;
  padding: 6px 10px;
  color: white;
}

.searchInput {
  border: 0px solid;
  height: 35px;
  outline: none;
  width: 100%;
  color: rgb(45, 43, 43);
  background-color: white;
  border-bottom: 1px solid #fb9819;
}

.searchInput:hover {
  border: 0px solid;
  border-bottom: 1px solid #fb9819;
}

.largeDeviceView {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10000000;
}

@media screen and (max-width: 1200px) {
  .largeDeviceView {
    display: none;
  }
}

.mobileView {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mobileView {
    display: block;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.IconStyle {
  color: #fb9819;
  font-size: 1.5rem;
  padding: 8px 8px;
}

.HeaderItems {
  display: flex;
  align-items: flex-end;
  float: right;
  padding-right: 4%;
}

.closeDrawer {
  float: right;
  text-align: right;
  font-size: 1.8rem;
  margin-right: 4%;
  color: rgb(64, 61, 61);
  cursor: pointer;
}

.drawerNotificationIcon {
  color: #fb9819;
  float: right;
  margin: auto;
  flex: end;
}

.drawerNotification {
  font-weight: 600;
  color: rgb(99, 98, 98);
}

.drawerInput {
  color: #fb9819;
  font-size: 1.5rem;
  padding: 0.6rem;
}

.drawerItems {
  margin: 0.5rem;
  color: rgb(56, 56, 56);
}
