.Button {
  margin-left: -9%;
  cursor: pointer;
  margin-top: 5.5%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Button {
    margin-left: -14%;
    margin-top: 9%;
  }
}

.searchInput {
  width: 600px;
  height: 40px;
  border: solid 1px rgb(65, 64, 64);
  border-radius: 50px;
  padding-left: 35px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 18px;
  outline: none;
  left: 10;
  position: relative;
  background-color: #ffffff;
  color: black;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 14px 30px #00000014;
  transition: all 0.5s;
  margin-top: 2rem;
}

.searchInput:focus {
  border: solid 1px #f67da9b0;
  box-shadow: 0px 14px 30px #f67da97e;
}

@media screen and (max-width: 768px) {
  .searchInput {
    max-width: 25rem;
    height: 1rem;
    border: solid 1px rgb(65, 64, 64);
    border-radius: 50px;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 10px;
    font-size: 1rem;
    outline: none;
    z-index: 0;
    position: relative;
    background-color: #ffffff;
    color: black;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
  }

  .searchInput:focus {
    border: solid 1px #f67da9b0;
    box-shadow: 0px 14px 30px #f67da97e;
  }
}

@media screen and (max-width: 430px) {
  .searchInput {
    max-width: 20rem;
  }
}

@media screen and (max-width: 340px) {
  .searchInput {
    font-size: 1rem;
    max-width: 15rem;
  }
}

.autoCompleteSuggetions {
  width: 540px;
  height: 260px;
  overflow-y: scroll;
  border-radius: 10px;
  padding-bottom: 20px;
  font-size: 18px;
  outline: none;
  z-index: 0;
  position: absolute;
  background-color: #ffffff;
  color: black;
  align-items: center;
  justify-content: center;
}

.searchButton {
  position: absolute;
  top: 6;
  right: 0;
  bottom: 0;
  left: 1;
  margin: auto;
  width: 90px;
  height: 62px;
  border: solid 1px #f77eaa;
  background-color: #f77eaa;
  border-radius: 0px 50px 50px 0px;
}

.searchButtonResult {
  width: 90px;
  height: 60px;
  border: solid 1px #f77eaa;
  background-color: #f77eaa;
}

@media screen and (max-width: 768px) {
  .searchButton {
    height: 3.1rem;
    width: 3rem;
  }

  .searchButtonResult {
    width: 4rem;
    height: 3rem;
  }

  .searchResultSearchBar {
    height: 3rem;
  }
}

.autoCompleteSuggetion {
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.ButtonComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -4%;
}

.searchResultSearchBar {
  width: 100%;
  /* height: 60px; */
  padding: 0.9rem 1rem;
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid #f77eaa;
  color: rgb(96, 95, 95);
  outline: none;
  font-size: 18px;
}

.filterWrapper {
  font-family: "Roboto";
}

.filterFeature {
  margin-top: 0.5rem;
}

.filterFeature h5 {
  color: #5c5c5c;
  font-family: "Space Grotesk";
  font-size: 1.2rem;
}
