.mainContainer {
  margin: 0 auto;
  background: rgb(217, 217, 230);
  background: linear-gradient(
    90deg,
    rgba(217, 217, 230, 1) 0%,
    rgba(255, 255, 255, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  height: 100%;
  width: 100%;
  padding: 30px;
  align-items: center;
  justify-items: center;
}

.notificationItemRead {
  background-color: white;
  padding: 10px;
}

.notificationContainer {
  margin: 0 180px;
  align-items: center;
  justify-items: center;
  background: #fff;
  border-radius: 30px;
  padding: 25px;
  width: auto;
  height: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .notificationContainer {
    margin: 0 10px;
  }
}

.notificationTitle {
  grid-area: title;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(74, 73, 73);
}

.notificationContent {
  grid-area: message;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(74, 73, 73);
}

.notificationItem {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 1px solid rgb(235, 235, 235);
  background-color: #fef1e3;
  font-weight: 600;
}

.notificationContentItemTitle {
  color: rgb(74, 73, 73);
}

.notificationContentItemTitleText {
  color: rgb(74, 73, 73);
}

.notificationContentItemTitleRead {
  font-weight: 500;
  color: rgb(111, 111, 111);
}

.notificationContentItemTitleUnread {
  font-weight: 500;
  color: rgb(62, 62, 62);
}
